import React from 'react';
import { ReactComponent as choosePageImg1 } from './Images/bg-1.svg';
import { ReactComponent as choosePageImg2 } from './Images/bg-2.svg';

import style from './index.module.scss';

export default function ChoosePage() {
    return (
        <div className={style['home']}>
            <div className={style['home__container']}>
                <div className={style['home__body']}>
                    <div className={style['home__item']}>
                        <div className={style['home__imgBox']}>
                            <img
                                // @ts-ignore
                                src={choosePageImg1}
                                alt=""
                            />
                        </div>
                    </div>

                    <div className={style['home__item']}>
                        <div className={style['home__imgBox']}>
                            <img
                                // @ts-ignore
                                src={choosePageImg2}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
