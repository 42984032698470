import { takeLatest } from 'redux-saga/effects';

import { CancelSagas } from '../../Utils/saga.utils';
import { leavePage } from './Routes.reducers';


export default function* rootSagas() {
    // @ts-ignore
    const tasks = [];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
