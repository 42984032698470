import React, { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';

import ChoosePage from '../../Pages/PreLogin/PreLogin';
import Signin from '../../Pages/Signin/Signin';

const RoutesContainer = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/sign-in');
    }, []);

    return (
        <Routes>
            <Route
                path="/"
                element={<ChoosePage />}
            />
            <Route
                path="/sign-in"
                element={<Signin />}
            />
        </Routes>
    );
};

export default RoutesContainer;
