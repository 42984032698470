// import React from 'react';

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { Alert } from "@mui/material";

// import LogoSvg from './Images/logo.svg';
import styles from './Signin.module.css';
import { googleLogin } from './Signin.reducer';
import { signinType } from './Signin.types';
import LoginGoogleSvg from './Images/login-google.svg';
import LoginWhiteSvg from './Images/logo-white.svg';
import LoginBackgroundSvg from './Images/login-backgroung.svg';

const Signin = () => {

    const dispatch = useDispatch();
    const user:signinType = useSelector(state => get(state, 'signin.user', {}));

    const onClickSignin = useCallback(() => {
        dispatch(googleLogin());
    }, []);

    const { accessToken } = user;
    return (
        <div className={styles.container}>
            <div className={styles.subContainer}>

                <img
                    src={LoginWhiteSvg}
                    alt=""
                    className={styles.logoWhite}
                />
                <img
                    src={LoginBackgroundSvg}
                    alt=""
                    className={styles.loginBackgroundSvg}
                />
                {!accessToken ?
                    <div
                        onClick={onClickSignin}>
                        <img
                            src={LoginGoogleSvg}
                            alt=""
                            className={styles.loginGoogleSvg}
                        />
                    </div>
                    : <div>
                        <Alert
                            severity="success"
                            sx={{ width: "100%" }}
                        >
                            Loggedin successful, you can use extension now...
                        </Alert>
                    </div>
                }

            </div>
        </div>
    );
};

export default Signin;
