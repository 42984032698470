/* eslint @typescript-eslint/no-empty-function: 0 */
/* eslint no-empty-pattern: 0 */
/* eslint @typescript-eslint/no-unused-vars: 0 */
/* eslint no-unused-vars: 0 */
/* eslint no-param-reassign: 0 */
import { createSlice } from '@reduxjs/toolkit';

const initialState = Object.freeze({
    user: {},
});

const reducers = {
    leavePage() {},
};

export const reducersPage = createSlice({
    name: 'routes',
    initialState,
    // @ts-ignore
    reducers,
});

export default reducersPage.reducer;

export const {
    leavePage
} = reducersPage.actions;
