import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import reducers from './reducers';
import { watchSagas } from './sagas';

const saga = createSagaMiddleware();

// redux dev tool
const composeEnhancers =
    // @ts-ignore
    (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(saga)));
saga.run(watchSagas);

/* eslint-disable */
export type RootState = ReturnType<typeof store.getState>;

export default store;
