import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import './index.css';
import App from './Containers/App/App';
import reportWebVitals from './reportWebVitals';
import store from './Store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId:  process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

firebase.initializeApp(config);

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

reportWebVitals();
