import { all, fork } from 'redux-saga/effects';
import routes from '../../Containers/Routes/Routes.sagas';
import signin from '../../Pages/Signin/Signin.sagas';


export function* watchSagas() {
    //@ts-ignore
    yield all([
        fork(routes),
        fork(signin),
    ]);
}
