import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios';
import { get } from 'lodash';
import store from '../Store';
import { googleLogin } from '../Pages/Signin/Signin.reducer';

export const apiBase = 'https://www.chatgpt4hiring.com/api';

const responseBody = (response: AxiosResponse) => {
    return response.data;
};

const responseHeader = (response: AxiosResponse) => {
    return response;
};
class API {
    instance: AxiosInstance;

    noAuth: boolean;

    getHeader: boolean;

    redirectOnError: boolean;

    constructor(
        noAuth?: boolean,
        getHeader?: boolean,
        redirectOnError?: boolean,
        config: AxiosRequestConfig = {
            baseURL: '/',
            timeout: 35000,
        }
    ) {
        this.instance = axios.create(config);
        this.noAuth = noAuth || false;
        this.getHeader = getHeader || false;
        this.redirectOnError = redirectOnError || false;
        // this.instance.interceptors.request.use(this.requestInterceptors);
        this.instance.interceptors.response.use(
            this.responseSuccessInterceptors,
            this.responseErrorInterceptors
        );
    }

    responseSuccessInterceptors = (response: AxiosResponse): AxiosResponse => response;

    responseErrorInterceptors = (response: AxiosError): AxiosError => {
        const error = get(response, 'response.data.error') || '';
        const isFirebaseTokenExpired =
            error.indexOf('Firebase ID token has expired') > -1;

        if (isFirebaseTokenExpired) store.dispatch(googleLogin());

        return response;
    };

    requestInterceptors = (params?: AxiosRequestConfig) => {
        const token = '';
        const newParams = {
            ...(params || {}),
            headers: {
                ...(get(params, 'headers') || {}),
                ...(token ? { Authorization: `Bearer ${token}` } : {}),
            },
            defaults: {
                mode: 'cors',
                withCredentials: false,
                credentials: 'same-origin',
                headers: {
                    ...(get(params, 'headers') || {}),
                    Authorization: `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers':
                        'Origin, X-Requested-With, Content-Type, Accept',
                    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
                    // 'Content-Type': 'application/json',
                },
            },
        };
        return newParams;
    };

    setLocalStorage = ({ key, value }: { key: string; value: string }) => {
        localStorage.setItem(key, value);
    };

    getLocalStorage = (key: string) => {
        return localStorage.getItem(key);
    };

    get = <T, R = AxiosResponse<T>>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<R> =>
        this.instance
            .get(url, config)
            .then(this.getHeader ? responseHeader : responseBody);

    post = <T, B, R = AxiosResponse<T>>(
        url: string,
        body: B,
        config?: AxiosRequestConfig
    ): Promise<R> =>
        this.instance
            .post(url, body, config)
            .then(this.getHeader ? responseHeader : responseBody);

    put = <T, B, R = AxiosResponse<T>>(
        url: string,
        body: B,
        config?: AxiosRequestConfig
    ): Promise<R> =>
        this.instance
            .put(url, body, config)
            .then(this.getHeader ? responseHeader : responseBody);

    delete = <T, R = AxiosResponse<T>>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<R> =>
        this.instance
            .delete(url, config)
            .then(this.getHeader ? responseHeader : responseBody);
}

export default API;
