
import { SagaIterator } from 'redux-saga';
import { takeLatest, call } from 'redux-saga/effects';
import { get } from 'lodash';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import store from '../../Store';
import { signinType } from './Signin.types';
import API, { apiBase } from '../../Utils/API';

import { CancelSagas } from '../../Utils/saga.utils';
import {
    googleLogin,
    leaveSignin,
    authLogin,
    setUser,
} from './Signin.reducer';

function* authLoginSaga({ payload }: { payload: any }): SagaIterator {
    try {
        const response = yield call(
            new API().post,
            `${apiBase}/auth/setup`,
            {
                name: payload.displayName == null ? 'random' : payload.displayName,
                email: payload.email,
            },
            {
                headers: {
                    appType: 'web',
                    version: 1.0,
                    timezone: -330,
                    //@ts-ignore
                    'x-authorization': payload.stsTokenManager.accessToken,
                },
            }
        );
        if(!response) return;

        store.dispatch(setUser({
            accessToken: response?.data?.authToken,
            _id: response.data.user._id,
            email: response.data.user.email,
        }));
        window.postMessage({
            data: {
                accessToken: response?.data?.authToken,
                _id: response.data.user._id,
                email: response.data.user.email,
            },
            type: 'AUTH_TOKEN_RESPONSE',
            for: 'CONTENT_SCRIPT'
        });
    } catch (e: unknown) {
        console.error('****authLoginSaga=', e);
    }
}

function* googleLoginSaga(): SagaIterator {
    try {
        const auth = firebase.auth();
        const googleProvider = new firebase.auth.GoogleAuthProvider();

        auth.onAuthStateChanged(async user => {
            if (!user) {

                auth.signInWithPopup(googleProvider)
                    .then(res => {
                        const currentUser: signinType = get(res, 'user.multiFactor.user') || {};
                        new API().setLocalStorage({
                            key: 'user',
                            value: JSON.stringify(currentUser),
                        });
                        store.dispatch(authLogin(currentUser));
                    })
                    .catch(error => {
                        console.log('*****error=', error);
                    });
            } else {
                const currentUser: signinType =
                    get(auth, 'currentUser.multiFactor.user') || {};
                new API().setLocalStorage({
                    key: 'user',
                    value: JSON.stringify(currentUser),
                });
                store.dispatch(authLogin(currentUser));
            }
        });
    } catch (e: unknown) {
        console.error('googleLoginSaga failed', get(e, 'response'));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(googleLogin.type, googleLoginSaga),
        // @ts-ignore
        yield takeLatest(authLogin.type, authLoginSaga),
    ];
    // @ts-ignore
    yield takeLatest(leaveSignin.type, CancelSagas, tasks);
}
