/* eslint @typescript-eslint/no-empty-function: 0 */
/* eslint no-empty-pattern: 0 */
/* eslint @typescript-eslint/no-unused-vars: 0 */
/* eslint no-unused-vars: 0 */
/* eslint no-param-reassign: 0 */
import { createSlice } from '@reduxjs/toolkit';
import { signinType } from './Signin.types';

const initialState = Object.freeze({
    user: {
        accessToken: '',
        _id: '',
        email: '',
    },
});

const signinReducers = {
    googleLogin({}, { payload }: { payload?: string }) {},
    authLogin({}, { payload }: { payload: any }) {},
    setUser(state: any, { payload }: { payload: signinType }) {
        console.log('****setUser=', payload);
        state.user = payload;
        document.cookie = `accessToken=${payload?.accessToken}`;
        document.cookie = `_id=${payload._id}`;
        document.cookie = `email=${payload.email}`;
    },
    authorizeEmail() {},
    leaveSignin() {},
};

export const signinPage = createSlice({
    name: 'signin',
    initialState,
    // @ts-ignore
    reducers: signinReducers,
});

export default signinPage.reducer;
export const {
    googleLogin,
    setUser,
    authLogin,
    authorizeEmail,
    leaveSignin
} = signinPage.actions;
